import React from "react";
import styled from "styled-components";

import CustomerQuote from "components/shared/CustomerQuote";
import customerLogos from "assets/home/customer-logos.jpg";

import mlsCameraGuyLogo from "assets/home/logo-mls-camera-guy.jpg";
import timKramerLogo from "assets/home/logo-tim-kramer-photography.webp";

function SocialProof() {
  return (
    <Wrapper>
      <Content>
        <Title></Title>
        <FeaturedSection>
          <CustomerQuote
            author="Tim @ Tim Kramer Photography"
            quote="I use PI every single day, literally. Love it to death."
            logo={timKramerLogo}
          />

          <CustomerQuote
            author="Art @ MLS Camera Guy"
            quote="I finally found the perfect way to collect payment for my
            photos! I looked at several alternatives, but none were as
            spot-on as PhotoInvoice."
            logo={mlsCameraGuyLogo}
          />
        </FeaturedSection>

        <Logos src={customerLogos} />
      </Content>
    </Wrapper>
  );
}

export default SocialProof;

const Wrapper = styled.div`
  // this is to get a white background under the wave part of the hero section
  padding-top: 50px;
  margin-top: -50px;

  background-color: white;
`;

const Content = styled.div`
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 0 0;
`;

const Title = styled.h2`
  font-size: 42px;
  text-align: center;
`;

const FeaturedSection = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Logos = styled.img`
  max-width: 100%;
  margin: 20px auto 0;
`;
